import React from 'react';
import thumb1 from '../images/about-img-001.png';
import thumb2 from '../images/about-img-002.jpg';


function About() {
    return(<section id='about'>
            <div className='about'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7 col-xs-12 order-lg-1 order-xs-2'>
                            <div className='thumbs-001'>
                                <img src={thumb1} />
                            </div>
                            <div className='thumbs-002'>
                                <img src={thumb2} />
                            </div>
                        </div>
                        <div className='col-lg-5 col-xs-12 order-first order-lg-2 order-xs-1'>
                            <span className='title'>
                                Elevate your<br/>Metaverse Experience
                            </span>

                            <div className='article-container'></div>
                            <div className='article-content'>
                            <p>GoMeta – the first ever media conference in the UAE to take place in the Metaverse will be held
 on September 21, 2022. GO Digital Marketing is taking it to the next level by bridging real life
 with the Metaverse by conducting a media conference on the virtual world with a panel of
 distinguished guests as it happens in any real-life media conference.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </section>)
}
export default About;