import React from 'react';
import logo from '../images/gometa-logo.png';


function Header() {
    return(<div className='header'>
            <div className='logo-container'>
                <img src={logo} className='logo-img' />
            </div>
        </div>)
}
export default Header;