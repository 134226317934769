import React from 'react';



function Footer() {
    return(<div className='footer'>
                <div className='footer-container'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                            <span>For partnership inquiries, you may send an email to <a href='mailto:metaverse@godigitalmarketing.ae'>metaverse@godigitalmarketing.ae</a></span>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <a href='mailto:metaverse@godigitalmarketing.ae' className='send-email'>Send an email</a>
                            </div>
                        </div>
                    </div>
                   
                   
                </div>
        </div>)
}
export default Footer;