import React from 'react';

class Register extends React.Component  {

    constructor(props) {
        super(props);
        /* this.state = {
           
                    AttendeeType: '',
                    Interest: '',
                    Fullname: '',
                    Company: '',
                    EmailAddress: '',
                    MobileNumber: '',
                    Message: ''
        } */
    }

    submitRequest(e) {
        e.preventDefault();

        //var form = document.getElementById('regform');

        //var xhr = new XMLHttpRequest();
        var data = new FormData();

        data.append('AttendeeType', document.getElementById('ddlAttendee').value);
        data.append('Interest', document.getElementById('ddlInterest').value);
        data.append('Fullname', document.getElementById('txtName').value);
        data.append('Company', document.getElementById('txtCompany').value);
        data.append('EmailAddress', document.getElementById('txtEmail').value);
        data.append('MobileNumber', document.getElementById('txtMobile').value);
        data.append('Message', document.getElementById('txtMessage').value);

        var proxy = 'https://cors-anywhere.herokuapp.com/';
        fetch(proxy + 'https://crm.godigitalmarketing.ae/Company/CaptureRegistration', 
        {
            method: "POST",
            body: data
        })
        .then(response => response.json())
        .then(response => {
            console.log(response);
        }).catch(err => {
            console.log(err);
        });
        
        document.getElementById('regForm').reset();
    }

    render() {
        return(<section id='register'>
            <div className='register'>
                <form id='regform'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <p>GoMeta will be debuting Metaverse spaces that have successfully pioneered real-life applications:<br/><br/></p>

                                <ul>
                                    <li>A collaboration with local home health care provider, Reyada Home Health Care Facilities, on a Metaverse space that has an integrated and fully working CRM system, complete with a medical education center.</li>
                                    <li>A recruitment firm, Skills Hub Recruitment Solutions, successfully conducted interviews with job applicants in the virtual realm in a custom-made boardroom.</li>
                                    <li>And a recent partnership with a Dubai-based fully licensed Metaverse service provider, Multi Verse Technologies, will be celebrated at the launch.</li>
                                </ul>

                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <h2 className='title'>Fill out the form to register</h2>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 col-xs-12 form-group'>
                                <label htmlFor='ddlAttendee'>Attendee</label>
                                <select id='ddlAttendee' name='AttendeeType' className='form-control' required>
                                    <option value='General Audience'>General Audience</option>
                                    <option value='Press/Media'>Press/Media</option>
                                </select>
                            </div>
                            <div className='col-lg-6 col-xs-12 form-group'>
                                <label htmlFor='ddlInterest'>Interest</label>
                                <select id='ddlInterest' name='Interest' className='form-control'  required>
                                    <option value='Metaverse'>Metaverse</option>
                                    <option value='NFT'>NFT</option>
                                    <option value='Crypto Currency'>Crypto Currency</option>
                                    <option value='Gaming'>Gaming</option>
                                    <option value='AR/VR/XR'>AR/VR/XR</option>
                                </select>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 col-xs-12 form-group'>
                                <label htmlFor='txtName'>Name</label>
                                <input type='text' id='txtName' name='Fullname' className='form-control' placeholder='Name'  required/>
                            </div>
                            <div className='col-lg-6 col-xs-12 form-group'>
                                <label htmlFor='txtCompany'>Company/Organization</label>
                                <input type='text' id='txtCompany' name='Company' className='form-control' placeholder='Company/Organization'  required/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 col-xs-12 form-group'>
                                <label htmlFor='txtEmail'>Email Address</label>
                                <input type='text' id='txtEmail' name='EmailAddress' className='form-control' placeholder='Email Address'  required/>
                            </div>
                            <div className='col-lg-6 col-xs-12 form-group'>
                                <label htmlFor='txtMobile'>Mobile Number</label>
                                <input type='text' id='txtMobile' name='MobileNumber' className='form-control' placeholder='Mobile Number'  required/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 form-group'>
                                <label htmlFor='txtMessage'>Message</label>
                                <textarea id='txtMessage' name='Message' rows='3' className='form-control' placeholder="Let us know what's on your mind, let's make it happen."></textarea>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 form-group'>
                            <button type='button' onClick={(e) => this.submitRequest(e)} className='btn btn-primary form-control'>SUBMIT</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            </section>)
    }
}
export default Register;