import './components/css/app.scss';
import Banner from './components/pages/banner.js';
import About from './components/pages/about.js';
import Register from './components/pages/register.js';
import Footer from './components/layout/footer.js';
import Header from './components/layout/header.js';


function App() {
  return (
    <div className="main-wrapper">
      <Banner />
      <About />
      <Register />
      <Footer />
      <Header />
    </div>
  );
}

export default App;
