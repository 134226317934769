import React from 'react';
import vrman from '../images/banner-vr.png';



function Banner() {
    return(<section id='banner'>
        <div className='banner'>
            <div id='fireflies'></div>
            <div className='banner-content'>
                <img src={vrman} className='vr-man' />
            </div>
            <div className='slogan'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-8 col-xs-12'>
                            <span>LAUNCH INTO THE</span>
                            <h1>Metaverse</h1>
                        </div>
                        <div className='col-lg-4 col-xs-12'>
                            <p>GoMeta Media Conference is powered by<br/>GO Digital Marketing your partner in<br/>creating new worlds in the Metaverse</p>
                            <a href='#register'>Let's Go!</a>
                        </div>
                    </div>
                </div>
            </div>
          
           
        </div>
    </section>)
}
export default Banner;

